import { useEffect, useState } from 'react';
import Vidstack from '../Vidstack';

export default function Vimeo({ videoId, onEnd, ...props }) {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    (async function() {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/vimeo/${videoId}/config`);
        const json = await res.json();
        setConfig(json);
      } catch (e) {
        console.error('Error fetching Vimeo config', e);
        onEnd();
      }
    })();
  }, [videoId, onEnd]);

  if (!config) {
    return null;
  }

  const src = config.request.files.hls.cdns[config.request.files.hls.default_cdn].url;

  return (
    <Vidstack
      {...props}
      onEnd={onEnd}
      src={src}
      playerTag="Vimeo"
    />
  );
}
