import Vidstack from '../Vidstack';

export default function Cloudflare({ videoId, ...props }) {
  return (
    <Vidstack
      {...props}
      src={`${process.env.REACT_APP_SAMPLE_CLOUDFLARE_BASE_URL}/${videoId}/manifest/video.m3u8`}
      playerTag='Cloudflare'
    />
  );
}
