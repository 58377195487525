import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Castr from "./Castr";
import CastrTablet from "./CastrTablet";
import { VideoPlayerRoute } from "./components/VideoPlayer";
import VideoPlayerComparer from './components/VideoPlayerComparer'
import { addRequestMetadata } from "./services/player-stats";
import { formatBytes } from "./components/VideoPlayer/Vidstack/util";

const originalOpen = XMLHttpRequest.prototype.open;
const originalSend = XMLHttpRequest.prototype.send;

XMLHttpRequest.prototype.open = function(method, url, async, user, password) {
  this._url = url;
  originalOpen.call(this, method, url, async, user, password);
};

XMLHttpRequest.prototype.send = function(body) {
  this._start = performance.now();
  this._ttfb = 0;
  this._duration = 0;

  this.addEventListener('load', function(event) {
    this._duration = performance.now() - this._start;
    const networkSpeed = this._duration && event.total ? event.total / this._duration : 0;
    addRequestMetadata(this._url, {
      ttfb: this._ttfb,
      computedDuration: this._duration,
      networkSpeed,
      responseSize: event.total,
    });
    const element = document.getElementById('networkSpeed');
    if (element) element.innerText = `${formatBytes(1000 * networkSpeed)}`;
  });

  this.addEventListener('readystatechange', function (event) {
    if (this.readyState === XMLHttpRequest.HEADERS_RECEIVED) { // HEADERS_RECEIVED
      this._ttfb = performance.now() - this._start;
    }
  });

  originalSend.call(this, body);
};

const router = createBrowserRouter([
  {
    path: "/compare",
    element: <VideoPlayerComparer />,
  },
  {
    path: "/:player/:videoId",
    element: <VideoPlayerRoute />,
  },
  {
    path: "/video/baghdadapp/:videoURL",
    element: <App />,
  },
  {
    path: "/video/baghdadapp/castr/:videoURL",
    element: <Castr />,
  },
  {
    path: "/video/baghdadapp/castrtab/:videoURL",
    element: <CastrTablet />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
