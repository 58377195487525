import { useParams } from 'react-router-dom';
import Cloudflare from './Cloudflare';
import Vimeo from './Vimeo';
import Castr from './Castr';
import Bunny from './Bunny';
import styles from './style.module.css';

const PLAYERS = {
  vimeo: (props) => <Vimeo {...props} />,
  bunny: (props) => <Bunny {...props} />,
  bunnyFast: (props) => <Bunny {...props} baseUrl="https://vz-957e7be2-b0a.b-cdn.net" playerTag="BunnyFast" />,
  bunnyProxy: (props) => (
    <Bunny
      {...props}
      baseUrl="https://cdn-proxy.corrsy.workers.dev/corrsy-cdn.educationforalliraqis.com"
      playerTag="bunnyProxy"
    />
  ),
  castr: (props) => <Castr {...props} />,
  cloudflare: (props) => <Cloudflare {...props} />,
}

export default function VideoPlayer({ player, ...props }) {
  return (
    <>
      <h1 className={styles.videoTitle}>
        Player: {player}
        <br />
        <span id="networkSpeed" />
      </h1>
      <div className={styles.videoPlayerContainer}>
        {PLAYERS[player](props)}
      </div>
    </>
  );
}

export function VideoPlayerRoute() {
  const params = useParams();

  return (
    <VideoPlayer {...params} />
  );
}
