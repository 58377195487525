import Vidstack from '../Vidstack';

export default function Castr({ videoId, ...props}) {
  return (
    <Vidstack
      {...props}
      src={`${process.env.REACT_APP_API_BASE_URL}/castr/${process.env.REACT_APP_SAMPLE_CASTR_VIDEO_FOLDER_ID}/${videoId}/index.m3u8`}
      buffersSrc='https://castr-vod.global.ssl.fastly.net'
      playerTag='Castr'
    />
  );
}
