import { useEffect, useRef, useState } from 'react';
import { convertTimeStampToDate } from './util';
import {
  logBufEvent,
  logPlayerStats,
} from '../../../services/player-stats';

export default function usePlayerStatsLogger({
  playerTag,
  duration,
  quality,
  onEnd,
  src,
}) {
  const [currentTime, setCurrentTime] = useState(0);

  const qualities = useRef([]);
  const bufStartTime = useRef({});
  const bufEvents = useRef([]);

  useEffect(() => {
    if (quality) {
      qualities.current.push(quality);
    }
  }, [quality]);

  const handleLoadStart = ({ timeStamp }) => {
    bufStartTime.current = {
      date: convertTimeStampToDate(timeStamp),
      num: timeStamp,
    };
  }

  const handleProgress = async ({ buffered }, { timeStamp }) => {
    const bufferIdx = buffered.length - 1;
    const bufferedStart = buffered.start(bufferIdx);
    const bufferedEnd = buffered.end(bufferIdx)

    const lastBufEvent = bufEvents.current[bufEvents.current.length - 1];

    const startTime = lastBufEvent?.endTime || bufStartTime.current.num;
    const startTimeDate = lastBufEvent?.endTimeDate || bufStartTime.current.date.toISOString();
    const endTimeDate = convertTimeStampToDate(timeStamp).toISOString();

    const bufEvent = {
      timestamp: endTimeDate,
      bufferedSeconds: bufferedEnd,
      bufferedSecondsFrom: bufferedStart,
      bufferedPercent: bufferedEnd / duration,
      startTime,
      startTimeDate,
      endTime: timeStamp,
      endTimeDate,
      duration: timeStamp - startTime,
      quality,
    };

    bufEvents.current.push(bufEvent);
    logBufEvent(playerTag, bufEvent);

    if (bufferedEnd === duration) {
      const totalBufferingDuration = timeStamp - bufStartTime.current.num;
      await logPlayerStats(playerTag, src, {
        timestamp: bufEvent.timestamp,
        bufferingStartTime: bufStartTime.current.date.toISOString(),
        bufferingEndTime: bufEvent.endTimeDate,
        totalBufferingDuration,
        bufferingEventsCount: bufEvents.current.length,
        qualities: qualities.current,
      });
      localStorage.setItem(playerTag.toLowerCase(), totalBufferingDuration.toFixed(2));
      onEnd && onEnd();
    }

    setCurrentTime(bufferedEnd);
  }

  return {
    currentTime,
    handleLoadStart,
    handleProgress,
  }
}
