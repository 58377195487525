import { useState } from 'react';
import VideoPlayer from '../VideoPlayer';
import styles from './style.module.css';
import { shuffleArray } from '../VideoPlayer/Vidstack/util';
import { getPlayerNetworkInfo } from '../../services/player-stats';

const PLAYERS = shuffleArray([
  { name: 'castr', videoId: process.env.REACT_APP_SAMPLE_CASTR_VIDEO_ID },
  // { name: 'cloudflare', videoId: process.env.REACT_APP_SAMPLE_CLOUDFLARE_VIDEO_ID },
  // { name: 'vimeo', videoId: process.env.REACT_APP_SAMPLE_VIMEO_VIDEO_ID },
  { name: 'vimeo', videoId: '990887673' },
  // { name: 'bunny', videoId: process.env.REACT_APP_SAMPLE_BUNNY_VIDEO_ID },
  { name: 'bunny', videoId: '7e934965-0793-430d-919d-5ff954b09de3' },
  { name: 'bunnyProxy', videoId: '7e934965-0793-430d-919d-5ff954b09de3' },
]);

export default function VideoPlayerComparer() {
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(-1);
  const currentPlayer = PLAYERS[currentPlayerIndex];
  const isNotStartedYet = currentPlayerIndex < 0;
  const isDone = currentPlayerIndex >= PLAYERS.length;

  const moveToNextPlayer = () => {
    setCurrentPlayerIndex(i => i + 1);
  }

  return (
    <div className={styles.container}>
      {isNotStartedYet && (
        <>
          <h1 className={styles.header}>
            مرحبا، اضغط على ابدأ حتى نبدي فحص الشبكة
          </h1>
          <span style={{display: 'inline-block'}}>
            <b>ارشادات سريعة</b>
            <ul>
              <li>راح يطلع يمك فيديو يقطع شوية ويحمل بطريقة غريبة</li>
              <li>خلي الفيديو مفتوح وانتظر لحد ما ينتهي الفحص</li>
              <li>لا تضغط على اي شي بالشاشة اثناء عملية الفحص</li>
              <li>لا تمشي او ترجع الفيديو، خلي يحمل وحده</li>
              <li>
                من تكمل العملية راح تظهر الك رسالة مكتوب عليها
                <br />
                <b>"شكرا لمشاركتك بتحسين خدمتنا"</b>
              </li>
            </ul>
          </span>
          <div>
            <button className={styles.button} onClick={moveToNextPlayer}>
              ابدأ
            </button>
          </div>
        </>
      )}
      {currentPlayer && (
        <VideoPlayer
          player={currentPlayer.name}
          videoId={currentPlayer.videoId}
          onEnd={moveToNextPlayer}
        />
      )}
      {isDone && (
        <>
          <div className={styles.container}>
            <h1 className={styles.header}>
              شكراً لمشاركتك بتحسين خدمتنا.
            </h1>
            <h3>
              العملية اكتملت. تگدر تغلق هذي الصفحة هسة.
            </h3>
          </div>
          <center><h3>احصائيات السرعة</h3></center>
          <table className={styles.resultsTable}>
            <thead>
              <tr>
                <th></th>
                <th>Duration</th>
                <th>TTFB</th>
                <th>Speed</th>
                <th>Speed2</th>
                <th>Size</th>
              </tr>
            </thead>
            <tbody>
              {PLAYERS.map(i => (
                <tr key={i.name}>
                  <td>{i.name}</td>
                  <td>{localStorage.getItem(i.name.toLowerCase()) ?? '-'}</td>
                  <td>{getPlayerNetworkInfo(i.name).ttfb}</td>
                  <td>{getPlayerNetworkInfo(i.name).speed}</td>
                  <td>{getPlayerNetworkInfo(i.name).speed2}</td>
                  <td>{getPlayerNetworkInfo(i.name).size}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
