import { useState, useEffect } from "react";
import "./App.css";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
function App() {
  const { videoURL } = useParams();
  let vimeourl =
    "https://player.vimeo.com/video/" +
    videoURL +
    "?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 20000);
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        right: "0",
        width: "100%",
        height: "100%",
        background: "black",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading && (
        <TailSpin
          visible={isLoading}
          height="80"
          width="80"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        />
      )}
      <iframe
        src={vimeourl}
        frameborder="0"
        allow="autoplay; fullscreen; controls;"
        style={{
          width: "100%",
          height: "100%",
        }}
        title="baghdadapp"
        onLoad={() => {
          setLoading(false);
        }}
      ></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
}

export default App;
