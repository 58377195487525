import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { useRef } from 'react';
import {
  MediaPlayer,
  MediaPlayerInstance,
  MediaProvider,
  useStore,
} from '@vidstack/react';
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default';
import usePlayerStatsLogger from './usePlayerStatsLogger';

export default function Vidstack({
  src,
  buffersSrc,
  playerTag,
  onEnd,
}) {
  const mediaPlayerRef = useRef();
  const { duration, quality } = useStore(MediaPlayerInstance, mediaPlayerRef);
  const {
    currentTime,
    handleProgress,
    handleLoadStart,
  } = usePlayerStatsLogger({ playerTag, duration, quality, onEnd, src: buffersSrc || src });

  return (
    <MediaPlayer
      playsInline
      muted
      autoplay
      src={src}
      ref={mediaPlayerRef}
      currentTime={currentTime}
      onLoadStart={handleLoadStart}
      onProgress={handleProgress}
      onError={e => {
        console.error('Error in MediaPlayer', e);
        localStorage.setItem(playerTag.toLowerCase(), 'error');
        onEnd();
      }}
    >
      <MediaProvider />
      <DefaultVideoLayout icons={defaultLayoutIcons} />
    </MediaPlayer>
  );
}
