import Vidstack from '../Vidstack';

export default function Bunny({ videoId, baseUrl, playerTag, ...props }) {
  return (
    <Vidstack
      {...props}
      src={`${baseUrl ?? process.env.REACT_APP_SAMPLE_BUNNY_BASE_URL}/${videoId}/playlist.m3u8`}
      playerTag={playerTag ?? 'Bunny'}
    />
  );
}
