import { convertTimeStampToDate, formatBytes } from "../components/VideoPlayer/Vidstack/util";

const LOGS_ENDPOINT = `${process.env.REACT_APP_API_BASE_URL}/logs`;
const INDEX = 'corrsy-video-analysis';

let unsavedEvents = [];

const sum = arr => arr.reduce((acc, num) => acc + num, 0);

async function writeLogEvents(playerTag) {
  const payload = {
    index: INDEX,
    events: unsavedEvents,
  };

  try {
    const res = await fetch(LOGS_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!res.ok) {
      console.error('Error sending data to OpenSearch. HTTP Error', res.status);
      return;
    }

    unsavedEvents = [];
  } catch (error) {
    console.error('Error sending data to OpenSearch:', error);
  }
}

export function logPlayerStats(playerTag, src, event) {
  const overallNetworkInfo = addNetworkEvents(playerTag, src);

  unsavedEvents.push({
    ...event,
    playerTag,
    tag: 'player_stats_event',
    overallNetworkInfo,
  });

  return writeLogEvents(playerTag);
}

function addNetworkEvents(playerTag, src) {
  const resourceEntries = performance.getEntriesByType('resource');
  const networkEvents = resourceEntries
    .filter(({ name }) => name.startsWith(src.match(/https?:\/\/[^/]+/)[0]))
    .map((entry) => ({
      ...entry.toJSON(),
      timestamp: convertTimeStampToDate(entry.responseEnd).toISOString(),
      startTimeDate: convertTimeStampToDate(entry.startTime).toISOString(),
      fetchStartDate: convertTimeStampToDate(entry.fetchStart).toISOString(),
      responseEndDate: convertTimeStampToDate(entry.responseEnd).toISOString(),
      filename: entry.name.split('/').pop().split('?')[0],
      ...getRequestMetadata(entry.name),
      playerTag,
    tag: 'network_request_event',
    }));

    unsavedEvents = unsavedEvents.concat(networkEvents);

    const ttfbs = networkEvents.filter(i => i.ttfb).map(i => i.ttfb);
    const avgTtfb = ttfbs.length ? sum(ttfbs) / ttfbs.length : 0;
    const speeds = networkEvents.filter(i => i.networkSpeed).map(i => i.networkSpeed);
    const avgSpeed = speeds.length ? sum(speeds) / speeds.length : 0;
    const totalSize = sum(networkEvents.map(i => i.responseSize ?? 0));
    const totalDuration = sum(networkEvents.map(i => i.duration ?? 0));
    const avgSpeed2 = totalDuration ? totalSize / totalDuration : 0;

    const networkInfo = {
      ttfb: avgTtfb.toFixed(2),
      speed: formatBytes(1000 * avgSpeed),
      speed2: formatBytes(1000 * avgSpeed2),
      size: formatBytes(totalSize, false),
    }
    setPlayerNetworkInfo(playerTag, networkInfo);
    return { avgTtfb, avgSpeed, avgSpeed2, totalSize, totalDuration };
}

export function logBufEvent(playerTag, event) {
  unsavedEvents.push({
    ...event,
    playerTag,
    tag: 'buffering_event',
  });
}

const requestMetadata = {};
export function addRequestMetadata(url, data) {
  requestMetadata[url] = data;
}

export function getRequestMetadata(url) {
  return requestMetadata[url] || {};
}

const playerNetworkInfo = [];
function setPlayerNetworkInfo(player, info) {
  playerNetworkInfo[player.toLowerCase()] = info;
}

export function getPlayerNetworkInfo(player) {
  return playerNetworkInfo[player.toLowerCase()] ?? {};
}