const loadTime = new Date();

export function convertTimeStampToDate(timeStamp) {
  const date = new Date(loadTime);
  date.setMilliseconds(date.getMilliseconds() + timeStamp);
  return date;
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i (inclusive)
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements array[i] and array[j]
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function formatBytes(bytes, isSpeed = true) {
  if (bytes === 0) return '0 bytes';

  const k = 1024;
  const sizes = isSpeed ? ['b/s', 'kbps', 'MB/s', 'GB/s'] : ['bytes', 'kb', 'MB', 'GB'];
  let i = Math.floor(Math.log(bytes) / Math.log(k));
  i = i < 0 ? 0 : i;
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}